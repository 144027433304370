import React from "react";
import './sports.css';


const Sports = () => {

    return (

        <div className="sports-container">
            
            <text className="titles">Sports</text>


                <div className="placeholder">content goes here</div>
        </div>

    )
}


export default Sports;