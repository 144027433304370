import React from "react";
import './crypto.css';


const Crypto = () => {

    return (

        <div className="crypto-container">
            
            <text className="titles">Crypto</text>


                <div className="placeholder">content goes here</div>
        </div>

    )
}


export default Crypto;