import React from "react";
import './news.css';


const News = () => {

    return (

        <div className="news-container">
            
            <text className="titles">News</text>


                <div className="placeholder">content goes here</div>
        </div>

    )
}


export default News;