import React from "react";
import './components/navbar'
import Navbar from "./components/navbar";
import Logo from "./components/logo";
import Shows from "./components/shows";
import Live from "./components/live";
import Sports from "./components/sports";
import News from "./components/news";
import Crypto from "./components/crypto";
import Footer from "./components/footer"


function App() {
  return (
    <>
      <Navbar/>
      <Logo/>
      <Live/>
      <Shows/>
      <Sports/>
      <News/>
      <Crypto/>
      <Footer/>
    </>
  );
}

export default App;
