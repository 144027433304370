import React from "react";
import './shows.css';
import Aux from '../assets/auxgodlogo.png';
import Ernesto from '../assets/ernestosgameshowlogo.png';
import IShorts from '../assets/independentshortslogo.png';
import Rapity from '../assets/rapityrapslogo.jpg';
import Bball from '../assets/2klogo.png';
import Goofiez from '../assets/goofiezlogo.png';
import LSU from '../assets/LSUlogo.png';
import OldDubs from '../assets/olddubslogo.png';
import Rent from '../assets/rentmoneylogo.png';



const Shows = () => {


   
     return(

        <div>

        <div className="shows-container">

                <div className="title"> Shows </div>

                <div className="pic-container">

                    <div className="pics-1">
                            <img className="auxgod" src={Aux} alt=""/>
                            <a href="https://auxgod.randy-tv.com/" className="link1">AuxGod Radio</a>

                            <img className="bball" src={Bball} alt=""/>
                            <a href="https://nba2k.randy-tv.com/" className="link2">NBA 2K on Randy.tv</a>
                        
                            <img className="lsu" src={LSU} alt="LSU"/>
                            <a className="link3">Local Stand Up</a>
                    </div>


                    <div className="pics-2">
                            <img className="shorts" src={IShorts} alt=""/>
                            <a href="https://ishorts.randy-tv.com/" className="link4">Independent Shorts</a>

                            <img className="rap" src={Rapity} alt=""/>
                            <a href="https://rapityraps.randy-tv.com/" className="link5">Rapity Raps</a>

                            <img className="ernesto" src={Ernesto} alt=""/>
                            <a href="https://ernesto.randy-tv.com/" className="link6">Ernesto's GameShow</a>
                    </div>

                    
                    <div className="pics-3">
                            <img className="rent" src={Rent} alt=""/>
                            <a href="https://rentmoney.randy-tv.com/" className="link7">Rent Money</a>

                            <img className="goofiez" src={Goofiez} alt=""/>
                            <a href="https://goofiez.randy-tv.com/" className="link8">Goofiez</a>

                            <img className="olddubs" src={OldDubs} alt=""/>
                            <a href="https://tmlk.randy-tv.com/" className="link9">T.M.L.K presents...</a>
                    </div>
                </div>
        </div>   


        

        </div>

    )
}


export default Shows;