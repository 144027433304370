import React from "react";
import './live.css';
import {DefaultPlayer as Video} from 'react-html5video'
import 'react-html5video/dist/styles.css'
import Crowd from '../videos/crowd-video.mov'
import thum from '../assets/2bootlegzlogo.png'


const Live = () => {

    return (

        <div className="live-container">
            
                <text className="titles">Live<span className="dot">.</span>tv</text>

                
                <div className="video-container">


                        
                    <Video 
                    autoPlay
                    muted
                    loop
                    poster={thum}
                    className='videoo'
                    playsInline
                    >
                  
                        <source src={Crowd} type="video/mp4" />

                    </Video>
                   



                </div>




        </div>

    )
}


export default Live;